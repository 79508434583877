export const COOKIE_KEYS = {
  JWT: "M4rP>pF%RN{2v8%B",
};

export const VERIFICATION_STATUS = {
  PENDING_REVIEW: "pending_review",
  APPROVED: "approved",
  RESUBMIT: "resubmitted",
  REJECTED: "rejected",
};

export const ADMIN_TYPES = {
  ADMIN: "admin",
  SUPER_ADMIN: "super_admin",
};
