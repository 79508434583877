import React, { useEffect, useState } from "react";
import { BsBarChartLine } from "react-icons/bs";
import { CiGlobe, CiSettings } from "react-icons/ci";
import { FaUsers } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  MdDiscount,
  MdOutlineDashboard,
  MdOutlineFeedback,
  MdOutlineProductionQuantityLimits,
} from "react-icons/md";
import {
  RiAdminFill,
  RiNotification2Line,
  RiUserShared2Fill,
} from "react-icons/ri";
import { VscRequestChanges } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/slices/auth";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import ApiManager, { instance } from "../../utils/ApiManager";
import { BiSolidOffer } from "react-icons/bi";
import { CiLogout } from "react-icons/ci";
import SidebarLink from "./SidebarLink";
import Button from "../Button";
import { twMerge } from "tailwind-merge";

const Sidebar = () => {
  const [unReviewedDriverCount, setUnreviewedDriverCount] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isSidebarCollapsed = useAppSelector(
    (state) => state.ui.isSidebarCollapsed
  );

  const getUnReviewedDriverCount = async () => {
    try {
      const res = await instance.get("/admin/driver/unreviewed/count");
      if (res.status === 200) {
        setUnreviewedDriverCount(res.data?.count);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUnReviewedDriverCount();
  }, []);

  const handleLogout = async () => {
    new ApiManager().logout();
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div
      className={twMerge(
        `font-inter flex pl-10 pt-4 flex-col sticky bg-white top-20 left-0 h-[calc(100vh-80px)] w-72 xl:w-[20%] flex-shrink-0`,
        isSidebarCollapsed && "hidden"
      )}
    >
      {/* Main container with flex layout */}
      <div className="flex flex-col h-full w-full">
        {/* Scrollable section for links */}
        <div className="flex-1 overflow-y-auto pr-2 flex flex-col gap-3">
          <SidebarLink
            icon={MdOutlineDashboard}
            label="Dashboard"
            link="/dashboard"
          />
          <SidebarLink
            icon={BsBarChartLine}
            label="Analytics"
            link="/dashboard/analytics"
          />
          <SidebarLink icon={FaUsers} label="Users" link="/dashboard/users" />
          <SidebarLink
            icon={FaUsers}
            label="Business Accounts"
            link="/dashboard/business-accounts"
          />
          <SidebarLink
            renderRight={
              unReviewedDriverCount > 0 ? (
                <span className="bg-green-100 ml-2 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                  {unReviewedDriverCount}
                </span>
              ) : null
            }
            icon={RiUserShared2Fill}
            label="Drivers"
            link="/dashboard/drivers"
          />
          <SidebarLink
            link="/dashboard/services"
            icon={MdOutlineProductionQuantityLimits}
            label="Services"
          />
          <SidebarLink
            link="/dashboard/feedback"
            icon={MdOutlineFeedback}
            label="Feedback"
          />
          <SidebarLink
            link="/dashboard/submit-requests"
            icon={VscRequestChanges}
            label="Submission Requests"
          />
          <SidebarLink
            link="/dashboard/help-desk"
            icon={IoIosHelpCircleOutline}
            label="Help Desk"
          />
          <SidebarLink
            link="/dashboard/notification"
            label="Notifications"
            icon={RiNotification2Line}
          />
          <SidebarLink icon={CiGlobe} label="States" link="/dashboard/states" />
          <SidebarLink
            icon={RiAdminFill}
            label="Admins"
            link="/dashboard/admins"
          />

          <SidebarLink
            icon={MdDiscount}
            label="Promo Codes"
            link="/dashboard/promo-codes"
          />
          <SidebarLink
            icon={BiSolidOffer}
            label="Offers"
            link="/dashboard/offers"
          />

          <SidebarLink
            link="/dashboard/accountSettings"
            label="Settings"
            icon={CiSettings}
          />
        </div>

        {/* Fixed logout section */}
        <div className="pt-4 pb-4 mt-2 border-t border-gray-100">
          <Button
            onClick={handleLogout}
            className="btn-ghost text-red-600 px-0"
          >
            <CiLogout size={20} className="text-red-600" />
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
