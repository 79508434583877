import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { login } from "./Redux/slices/auth";
import { useAppDispatch, useAppSelector } from "./Redux/store";
import Dashboard from "./components/Dashboard";
import DashboardLayout from "./components/DashboardLayout";
import Error from "./components/Error";
import Feedback from "./components/Feedback";
import InDevelopment from "./components/InDevelopment";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import ServiceDetails from "./components/ServiceDetails";
import ServicesDashboard from "./components/ServicesDashboard";
import Withdrawl from "./components/Withdraw";
import firebase from "./firebase";
import socket from "./lib/socket-io";
import Admins from "./pages/Admins";
import Analytics from "./pages/Analytics";
import DashboardDrivers from "./pages/DashboardDrivers";
import DashboardUsers from "./pages/DashboardUsers";
import DriverDetails from "./pages/DriverDetails";
import ForgotPassword from "./pages/ForgotPassword";
import HelpDesk from "./pages/HelpDesk";
import Notification from "./pages/Notification";
import Offers from "./pages/Offers";
import PromoCodes from "./pages/PromoCodes";
import AccountSettings from "./pages/Settings";
import States from "./pages/States";
import SubmissionRequests from "./pages/SubmissionRequests";
import UserDetails from "./pages/UserDetails";
import ApiManager from "./utils/ApiManager";
import { getSessionCookie } from "./utils/cookies";
import BusinessAccounts from "./pages/BusinessAccounts";
import BusinessAccountDetail from "./pages/BusinessAccountDetail";
// import { getSessionCookie } from "./utils/cookies";

function App() {
  // const { pathname } = useLocation();
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  const messaging = firebase.messaging();

  const checkWhoAmI = async () => {
    const getMe = await new ApiManager().WhoAmI();
    if (getMe.success) {
      dispatch(login(getMe?.payload?.data));
    }
  };

  useEffect(() => {
    const token = getSessionCookie("token");
    if (token) {
      checkWhoAmI();
    }
  }, []);

  // React.useEffect(() => {
  //   if (!getSessionCookie("token")) {
  //     navigate("/login");
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    messaging
      .getToken({
        vapidKey:
          "BLwe3ejWxaUV3oN63ING_wjzYkfnWBZjJ4_mUK206S4JucYf42C_Eun9ic2p3PuiZeE0X0nC4qSftRo2QcHRPvo",
      })
      .then((currentToken: any) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          new ApiManager().getNotifications(currentToken);
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err: any) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  }, []);

  const userId = useAppSelector((state) => state.auth.user?._id);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("socket connected");
    });
    socket.on("on_join", (data) => {
      console.log("Socket: On Join", data);
    });
    socket.on("exit", (data) => {
      console.log("Socket: On Exit", data);
    });
    socket.on("disconnect", (data) => {
      console.log("Socket: On Disconnect", data);
    });
  }, []);

  useEffect(() => {
    if (socket.connected && userId) {
      socket.emit("join", userId);
    }
  }, [userId]);

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="business-accounts" element={<BusinessAccounts />} />
            <Route
              path="business-accounts/:id"
              element={<BusinessAccountDetail />}
            />
            <Route path="users" element={<DashboardUsers />} />
            <Route path="drivers" element={<DashboardDrivers />} />
            <Route path="accountSettings" element={<AccountSettings />} />
            <Route path="users/:id" element={<UserDetails />} />
            <Route path="drivers/:id" element={<DriverDetails />} />
            <Route path="services" element={<ServicesDashboard />} />
            <Route path="withdraw" element={<Withdrawl />} />
            <Route path="services/:id" element={<ServiceDetails />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="notification" element={<Notification />} />
            <Route path="help-desk" element={<HelpDesk />} />
            <Route path="submit-requests" element={<SubmissionRequests />} />
            <Route path="admins" element={<Admins />} />
            <Route path="states" element={<States />} />
            <Route path="promo-codes" element={<PromoCodes />} />
            <Route path="offers" element={<Offers />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="*" element={<InDevelopment />} />
          </Route>
        </Route>
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
