import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Line from "../assets/Line 47.png";
import BackButton from "../components/BackButton";
import EnlargedImage from "../components/Image";
import ApiManager from "../utils/ApiManager";
import { formatDate } from "../utils/helpers";
import { BussinessAccountType } from "./BusinessAccounts";

const BusinessAccountDetail = () => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<BussinessAccountType | null>(null);
  const { id } = useParams();

  const handleGetBusinessDetails = async () => {
    setLoading(true);
    const res: any = await new ApiManager().getBusinessAccountDetails(id || "");
    if (res?.success) {
      setDetails({
        ...res.data?.data,
        totalOrders: res.data?.totalOrders,
        completedOrders: res.data?.completedOrders,
      });
    }
    setLoading(false);
  };

  const [image, setImage] = useState<string | null>(null);
  const [zoomed, setZoomed] = useState(false);

  useEffect(() => {
    if (id) {
      handleGetBusinessDetails();
    }
  }, [id]);

  return (
    <div className="py-10 flex flex-col justify-center font-inter bg-gray-100 min-h-screen">
      <BackButton />
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-3xl font-bold mr-4">Business Account details</h1>
      </div>
      <div
        style={{ border: "1px solid rgba(228, 228, 231, 1)" }}
        className="w-full p-6 flex  items-center bg-white rounded-lg"
      >
        {loading ? (
          <div className="w-full h-full pb-20 flex justify-center">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div style={{ width: "40%" }} className=" flex flex-col gap-2">
              <img
                className="w-32 h-32 rounded-full"
                onClick={() => {
                  setImage(details?.profileImage || "");
                  setZoomed(true);
                }}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src =
                    "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png";
                }}
                src={details?.profileImage}
                alt="User"
              />
              <p className="uppercase text-primary font-bold mt-2">
                {details?.fullName}
              </p>

              <p>
                <strong>Email: </strong> {details?.email}
              </p>
              <p>
                <strong>Phone number: </strong>
                {details?.phone || ""}
              </p>
              <p>
                <strong>Joined: </strong> {formatDate(details?.createdAt || "")}
              </p>
              <p>
                <strong>State: </strong>
                {details?.state || "N/A"}
              </p>
            </div>
            <img src={Line} alt="Line" className="h-80" />
            <div style={{ width: "60%" }} className="pl-4 flex flex-col gap-2">
              <div className="flex items-start justify-between relative">
                <p className="font-bold text-primary">Details</p>
              </div>
              <p>
                <strong>Total orders: </strong>
                {details?.totalOrders || 0}
              </p>
              <p>
                <strong>Completed orders: </strong>
                {details?.completedOrders || 0}
              </p>
              <p>
                <strong>Referrer points: </strong>
                {details?.points || 0}
              </p>
              <p>
                <strong>Business Name: </strong>
                {details?.businessName || "N/A"}
              </p>
              <p>
                <strong>Business Address: </strong>
                {details?.businessAddress?.address || "N/A"}
              </p>
              <p>
                <strong>Business Website: </strong>
                {details?.businessWebsite || "N/A"}
              </p>
              <p>
                <strong>Main Products of Delivery: </strong>
                {details?.mainProductsOfDelivery?.join(", ") || "N/A"}
              </p>
            </div>
          </>
        )}
      </div>

      <EnlargedImage isVisible={zoomed} setVisible={setZoomed} src={image} />
    </div>
  );
};

export default BusinessAccountDetail;
