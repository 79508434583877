import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { decrementCount } from "../Redux/slices/ui";
import { useAppDispatch } from "../Redux/store";
import Line from "../assets/Line 47.png";
import BackButton from "../components/BackButton";
import ConfirmationDialog from "../components/ConfirmationDialog";
import ResubmittedDocuments from "../components/DriverDetails/ResubmittedDocuments";
import EnlargedImage from "../components/Image";
import PayoutModal from "../components/PayoutModal";
import Rating from "../components/Rating";
import Table from "../components/Table";
import VerifyDriverBackgroundBtn from "../components/VerifyDriverBackgroundBtn";
import useVehicleTypes from "../hooks/useVehicleTypes";
import ApiManager from "../utils/ApiManager";
import { VERIFICATION_STATUS } from "../utils/Enums";
import { formatDate, formatPrices } from "../utils/helpers";
import { DriverType } from "./DashboardDrivers";
import RejectReason from "../components/DriverDetails/RejectReason";
import MapContainer from "../components/Map";
import Button from "../components/Button";

type StatusType = "approved" | "rejected";

type DriverDetailsType = DriverType & {
  totalEarning: number;
  ratingCount: number;
  rating: number;
  pendingForClearance: number;
  vehicleMake: string;
  vehicleModel: string;
  vehicleMakeYear: string;
  gender: string;
  driverLicenseNumber: string;
  driverLicenseState: string;
  dob: string;
  isOnline: boolean;
  isBlocked: boolean;
  state: string;
  coords: {
    type: string;
    coordinates: number[];
  };
};

const DriverDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGettingAddress, setIsGettingAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<DriverDetailsType | null>(null);
  const [isMessageVisible, setMessageVisible] = useState(false);
  const [isPayoutVisible, setPayoutVisible] = useState(false);
  const [isViewingResubmittedDocuments, setIsViewingResubmittedDocuments] =
    useState(false);
  const [isRejectReasonModalVisible, setIsRejectReasonModalVisible] =
    useState(false);
  const [driverAddress, setDriverAddress] = useState<string | null>(null);

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { getVehicleLabel } = useVehicleTypes();

  const handleGetDriverDetails = async () => {
    setLoading(true);
    const res: any = await new ApiManager().getDriverDetails(id);
    if (res?.success) {
      setDetails(res.data);
    }
    setLoading(false);
  };

  const closePayoutModal = () => {
    setPayoutVisible(false);
  };

  const [image, setImage] = useState<string | null>(null);
  const [zoomed, setZoomed] = useState(false);

  const changeStatus = async (value: StatusType, reason?: string) => {
    if (!details?.isEmailVerified) {
      setMessageVisible(true);
      return;
    }
    setIsLoading(true);
    const message = {
      approved: "Driver Approved Successfully",
      rejected: "Driver Rejected Successfully",
    };

    let body: { verificationStatus: StatusType; rejectReason?: string } = {
      verificationStatus: value,
    };

    if (value === "rejected" && reason) {
      body.rejectReason = reason;
    }

    const res: any = await new ApiManager().modifyDriver(id || "", body);

    if (res?.success) {
      handleGetDriverDetails();
      toast(message[value], { type: "success" });
    }
    dispatch(decrementCount());
    setIsRejectReasonModalVisible(false);
    setIsLoading(false);
  };

  const handleGetDriverAddress = async () => {
    setIsGettingAddress(true);
    const res = await new ApiManager().getDriverAddress(id || "");
    if (res?.success) {
      setDriverAddress(res?.data?.address);
    } else {
      toast(res?.error || "Something went wrong", { type: "error" });
    }
    setIsGettingAddress(false);
  };

  useEffect(() => {
    handleGetDriverDetails();
  }, []);

  return (
    <div className="py-10 flex flex-col justify-center font-inter bg-gray-100 min-h-screen">
      <BackButton />
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-3xl font-bold mr-4">Driver details</h1>
        {!loading &&
          details?.verificationStatus ===
            VERIFICATION_STATUS.PENDING_REVIEW && (
            <div>
              <button
                onClick={() => changeStatus("approved")}
                className={`px-8 py-2 rounded-xl ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed"
                    : "opacity-100 cursor-pointer"
                } border border-primary text-primary`}
              >
                Allow
              </button>
              <button
                onClick={() => {
                  if (!details?.isEmailVerified) {
                    setMessageVisible(true);
                    return;
                  }
                  setIsRejectReasonModalVisible(true);
                }}
                // onClick={() => changeStatus("rejected")}
                className={`px-8 py-2 rounded-xl ml-2 ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed"
                    : "opacity-100 cursor-pointer"
                } border border-red-600 text-red-600`}
              >
                Reject
              </button>
              {!details.isEmailVerified ? (
                <p className="text-sm text-gray-600 mt-2">
                  Email is not verified yet
                </p>
              ) : null}
            </div>
          )}
      </div>
      <div
        style={{ border: "1px solid rgba(228, 228, 231, 1)" }}
        className="w-full p-6 flex  items-center bg-white rounded-lg"
      >
        {loading ? (
          <div className="w-full h-full pb-20 flex justify-center">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div style={{ width: "40%" }} className=" flex flex-col gap-2">
              <img
                className="w-32 h-32 rounded-full"
                onClick={() => {
                  setImage(details?.profileImage || "");
                  setZoomed(true);
                }}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src =
                    "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png";
                }}
                src={details?.profileImage}
                alt="User"
              />
              <p className="uppercase text-primary font-bold mt-2">
                {details?.fullName}
              </p>
              <p>
                <strong>Total earning: </strong>

                {formatPrices(details?.totalEarning || 0)}
              </p>
              <div className="flex items-center">
                <strong className="mr-1">Rating: </strong>
                <Rating
                  ratingcount={details?.ratingCount || 0}
                  rating={details?.rating || 0}
                />
              </div>
              <p>
                <strong>Email: </strong> {details?.email}
              </p>
              <p>
                <strong>Phone number: </strong>
                {details?.phone || ""}
              </p>
              <p>
                <strong>Joined: </strong> {formatDate(details?.createdAt || "")}
              </p>
              <p>
                <strong>Date of Birth: </strong>
                {formatDate(details?.dob || "")}
              </p>
              <p>
                <strong>License Number: </strong>
                {details?.driverLicenseNumber || "N/A"}
              </p>

              <p>
                <strong>State: </strong>
                {details?.state || "N/A"}
              </p>
            </div>
            <img src={Line} alt="Line" className="h-80" />
            <div style={{ width: "60%" }} className="pl-4 flex flex-col gap-2">
              <div className="flex items-start justify-between relative">
                <p className="font-bold text-primary">Details</p>
                <div className="space-y-4">
                  {!details?.candidate_id &&
                    details?.verificationStatus ===
                      VERIFICATION_STATUS.PENDING_REVIEW && (
                      <VerifyDriverBackgroundBtn
                        onSuccess={handleGetDriverDetails}
                        id={id || ""}
                      />
                    )}
                  {details?.coords?.coordinates &&
                    details?.coords?.coordinates?.length > 0 && (
                      <div className="right-0 absolute w-72">
                        <div className="h-56 rounded-2xl gap-4 w-full flex flex-col items-center">
                          <MapContainer
                            latt={details?.coords?.coordinates[1]}
                            lngg={details?.coords?.coordinates[0]}
                          />
                          <Button
                            isLoading={isGettingAddress}
                            onClick={handleGetDriverAddress}
                          >
                            Get Address
                          </Button>
                        </div>
                        {driverAddress && (
                          <p className="mt-4">
                            <strong>Driver Address: </strong>
                            {driverAddress}
                          </p>
                        )}
                      </div>
                    )}
                </div>
              </div>

              <p>
                <strong>Status: </strong>
                {details?.isOnline
                  ? "Online"
                  : !details?.isOnline
                  ? "Offline"
                  : "N/A"}
              </p>
              <p>
                <strong>Vehicle number: </strong>
                {details?.vehicleNumber || ""}
              </p>
              <p>
                <strong>Vehicle type: </strong>
                {getVehicleLabel(details?.vehicleType || "")}
              </p>
              <p>
                <strong>Wallet: </strong>
                {formatPrices(details?.wallet || 0)}
              </p>
              <p>
                <strong>Pending for clearance: </strong>
                {formatPrices(details?.pendingForClearance || 0)}
              </p>
              <p>
                <strong>Total orders: </strong>
                {details?.totalOrders || 0}
              </p>
              <p>
                <strong>Completed orders: </strong>
                {details?.completedOrders || 0}
              </p>
              <p>
                <strong>Vehicle Make: </strong>
                {details?.vehicleMake || "N/A"}
              </p>
              <p>
                <strong>Vehicle Model: </strong>
                {details?.vehicleModel || "N/A"}
              </p>
              <p>
                <strong>Vehicle Year: </strong>
                {details?.vehicleMakeYear || "N/A"}
              </p>
              <p>
                <strong>License State: </strong>
                {details?.driverLicenseState || "N/A"}
              </p>

              <div className="flex mt-2 items-center gap-2">
                {details?.vehicleInsuranceFile ? (
                  <a
                    rel="noreferrer"
                    href={details?.vehicleInsuranceFile}
                    target="_blank"
                  >
                    <button
                      style={{
                        background:
                          "linear-gradient(357.95deg, #339500 1.23%, #77CB02 97.91%)",
                      }}
                      className="px-4 py-2 text-white rounded-lg"
                    >
                      Vehicle insurance file
                    </button>
                  </a>
                ) : null}
                {details?.vehicleRegistrationFile ? (
                  <a
                    rel="noreferrer"
                    href={details?.vehicleRegistrationFile}
                    target="_blank"
                  >
                    <button
                      style={{
                        background:
                          "linear-gradient(357.95deg, #339500 1.23%, #77CB02 97.91%)",
                      }}
                      className="px-4 py-2 text-white rounded-lg"
                    >
                      Vehicle registration file
                    </button>
                  </a>
                ) : null}
                {details?.drivingLicenseFile ? (
                  <a
                    rel="noreferrer"
                    href={details?.drivingLicenseFile}
                    target="_blank"
                  >
                    <button
                      style={{
                        background:
                          "linear-gradient(357.95deg, #339500 1.23%, #77CB02 97.91%)",
                      }}
                      className="px-4 py-2 text-white rounded-lg"
                    >
                      Driving license file
                    </button>
                  </a>
                ) : null}
              </div>
              {details?.verificationStatus === VERIFICATION_STATUS.RESUBMIT ||
              details?.verificationStatus ===
                VERIFICATION_STATUS.PENDING_REVIEW ? (
                <button
                  onClick={() => setIsViewingResubmittedDocuments(true)}
                  className="text-primary mt-4"
                >
                  View resubmitted documents
                </button>
              ) : null}
            </div>
          </>
        )}
      </div>

      <Table driverId={id} />
      <EnlargedImage isVisible={zoomed} setVisible={setZoomed} src={image} />
      <PayoutModal
        driver={details?._id}
        inWallet={details?.wallet}
        isVisible={isPayoutVisible}
        onClose={closePayoutModal}
      />
      <ConfirmationDialog
        messageMode={true}
        isVisible={isMessageVisible}
        onHide={() => setMessageVisible(false)}
        text="Email is not verified yet"
        closeButtonText="Close"
      />
      <ResubmittedDocuments
        onApproveOrRejectSuccess={handleGetDriverDetails}
        isVisible={isViewingResubmittedDocuments}
        onHide={() => setIsViewingResubmittedDocuments(false)}
        driverId={id}
      />
      <RejectReason
        isLoading={isLoading}
        isVisible={isRejectReasonModalVisible}
        onHide={() => setIsRejectReasonModalVisible(false)}
        onConfirm={(reason) => {
          changeStatus("rejected", reason);
        }}
      />
    </div>
  );
};

export default DriverDetails;
